import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    chatMemberdata: [],
    room:'', 
    chatwith:'',
    TotalUnRead: 0

};

const ChatMemberSlice = createSlice({
    name: "chatMemberdata",
    initialState,
    reducers: {
        
        setChatMemberDataList:(state, action) => {
            state.chatMemberdata = action.payload;
        },
        setRoomDetails:(state, action) => {
            state.room = action.payload;
        },
        setChatWith:(state, action) => {
            state.chatwith = action.payload;
        },
        setTotalUnRead:(state, action) => {
            state.TotalUnRead = action.payload;
        },
    },
    
})

export const { setChatMemberDataList, setRoomDetails, setChatWith, setTotalUnRead } = ChatMemberSlice.actions;
export default ChatMemberSlice.reducer;