import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    plandata: []
};

const planDataSlice = createSlice({
    name: "planData",
    initialState,
    reducers: {
        setPlanDataList:(state, action) => {
            state.plandata = action.payload;
        },
    }
})

export const { setPlanDataList } = planDataSlice.actions;
export default planDataSlice.reducer;