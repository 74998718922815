// requestCountSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../utils/comman"; // Import your utility functions

export const fetchSiteSettings = createAsyncThunk(
  "fetchSetting/fetchsiteSettings",
  async (_, thunkAPI) => {
    try {
      const response = await utils.callAPI("get", "/contact/details");
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  siteSettings: null,
  //   isLoading: false,
  isError: false,
};

const siteSettingsSlice = createSlice({
  name: "siteSettingsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSiteSettings.pending, (state) => {
        // state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchSiteSettings.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.siteSettings = action.payload;
        // state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchSiteSettings.rejected, (state) => {
        // state.isLoading = false;
        state.isError = true;
      });
  },
});

export default siteSettingsSlice.reducer;
