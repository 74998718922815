// LoaderOverlay.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./loader.css";
import { useLoader } from "./Loader";
import { InfinitySpin } from "react-loader-spinner";
import { HashLoader } from "react-spinners";

function LoaderOverlay() {
  const { loading } = useLoader();

  return loading ? (
    <div className="loader-overlay">
      <div className="loader">
        {/* <HashLoader cssOverride={{width:'350px', height:'350px'}} color="#ec3237" /> */}
        <InfinitySpin width="200" color="#ec3237" />
      </div>
    </div>
  ) : null;
}

export default LoaderOverlay;
