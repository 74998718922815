import axios from "axios";

const instance = axios.create({
  //baseURL: "https://49silverstonedrive.ca/webmobischool/api",

  // baseURL: "https://canadianhinduchamberofcommerce.ca/services/api", // Live URL
  //  baseURL: "https://canadianhinduchamberofcommerce.ca/stg/services/api", // STG URL


     // baseURL: "https://canadianhinduchamberofcommerce.ca/services/api", // Live URL
     baseURL: process.env.REACT_APP_API_URL,


	headers: {
		//   "Access-Control-Allow-Origin": "*",
		//   "withCredentials": true,
		//       "crossOrigin": true,
		//"Content-Type": "application/json",
		//"Accept": "application/json",
		//Authorization: "Bearer " + sessionStorage.getItem("memberAuth"),
		//'Authorization': 'Bearer 123'
	},

});

export default instance;
