// actions/auth.js

// Action Types
export const SET_NEW_USER_STATUS = 'SET_NEW_USER_STATUS';

// Action Creators
export const setNewUserStatus = (isNewUser) => ({
  type: SET_NEW_USER_STATUS,
  payload: isNewUser,
});

// reducers/auth.js

const initialState = {
  isNewUser: '', // Default to false or an appropriate initial value
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_USER_STATUS:
      return {
        ...state,
        isNewUser: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
