import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    memberdata: []
};

const memberDataSlice = createSlice({
    name: "memberData",
    initialState,
    reducers: {
        setMemberDataList:(state, action) => {
            state.memberdata = action.payload;
        },
    }
})

export const { setMemberDataList } = memberDataSlice.actions;
export default memberDataSlice.reducer;