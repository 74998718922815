import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  paymentResponse: {},
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setpaymenyResponse: (state, action) => {
      // console.log(action)
      state.paymentResponse = action.payload;
    },
  },
});

export const { setpaymenyResponse } = paymentSlice.actions;
export default paymentSlice.reducer;
