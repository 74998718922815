import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import { useLoader } from "./Loader";
import LoaderOverlay from "./LoaderOverlay";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { fetchSiteSettings } from "./reducers/siteSettings";
import { useDispatch } from "react-redux";
import "./components/Members/assets/script";
import { io } from "socket.io-client";
//import { secSocketConnection } from "../src/reducers/socketConSlice";
//import socket from "./components/Members/components/chat/Socket";
// import Home from "./components/Home/Home";
// import AboutUs from "./components/Home/Pages/About";
// import ContactUs from "./components/Home/Pages/Contact";
// import Membership from "./components/Home/Pages/Membership";
const AdminModule = React.lazy(() => import("./components/admin/Admin"));
const Layout = React.lazy(() => import("./components/Home/Layout"));

// const Memberlayout = React.lazy(() => import("./components/Members/Memberlayout"));

function App() {
  const pathName = window.location.pathname;
  const pathFragment = pathName.split("/");
  const dispatch = useDispatch();

  const { loading } = useLoader();
  const [isSocket, setIsSocket] = useState(false);
  const isAdmin = () => {
    return pathFragment.some((item) => item === "admin");
  };

  // const isMember = () => {

  //   return pathFragment.some((item) => item === "member");

  // };

  const isFront = () => {
    return !isAdmin() ? true : false;
  };

  useEffect(() => {
    dispatch(fetchSiteSettings());
  });
  // useEffect(() => {
  //   if (!isSocket) {
  //     const socket = io('http://localhost:3000/', {
  //       query: {
  //         "app_key": "CHCC_jhacYNKmYG",
  //         "secret_key": "8A85C96CDB2B3C15CF6B184C2654B"
  //       }
  //     });
  //     dispatch(secSocketConnection(socket));
  //     setIsSocket(true);
  //   }
  // },[isSocket])
  return (
    <>
      <Router basename="/">
        <LoaderOverlay />
        <Routes>
          {isFront() ? (
            <>
              <Route path="/*" element={<Layout />} />
            </>
          ) : (
            ""
          )}

          {isAdmin() ? (
            <Route exact path="/admin/*" element={<AdminModule />} />
          ) : (
            ""
          )}

          {/* {isMember() ? (

          <Route  path="/member/*" element={<Memberlayout />} />

        ) : (

          ""

        )} */}

          {/* <Route exact path="/" element={<Home />} />

        <Route exact path="/about-us" element={<AboutUs />} />

        <Route exact path="/contact-us" element={<ContactUs />} />

        <Route exact path="/membership-plans" element={<Membership />} /> */}
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
