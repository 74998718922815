import { configureStore } from "@reduxjs/toolkit";
import planDataSlice from "./reducers/planDataSlice";
import memberDataSlice from "./reducers/memberDataSlice";
import requestCountReducer from "./reducers/CounteSlice";
import siteSettings from "./reducers/siteSettings";
import notificationCountSlice from "./reducers/notificationSlice";
import profileReducer from "./reducers/userProfile";
import chatMemberSlice from "./reducers/ChatMemberSlice";
import PaymentSlice from "./reducers/PaymentSlice";
import authReducer from "./reducers/auth";


export const store = configureStore({

  reducer: {
    
    planData: planDataSlice,
    memberData: memberDataSlice,
    requestCount: requestCountReducer,
    siteSettings: siteSettings,
    notificationData: notificationCountSlice,
    profile: profileReducer,
    chatMemberdata: chatMemberSlice,
    payment: PaymentSlice,
    auth: authReducer,
  },  
  //  middleware: getDefaultMiddleware =>
  // getDefaultMiddleware({
  //   serializableCheck: false,
  // }),
});
