// requestCountSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../utils/comman"; // Import your utility functions

export const fetchRequestCount = createAsyncThunk(
  "requestCount/fetchRequestCount",
  async (_, thunkAPI) => {
    try {
      const response = await utils.callAPI(
        "get",
        "/member/connection/request/listCount"
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  requestCount: null,
  //   isLoading: false,
  isError: false,
};

const requestCountSlice = createSlice({
  name: "requestCount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestCount.pending, (state) => {
        // state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchRequestCount.fulfilled, (state, action) => {
        state.requestCount = action.payload;
        // state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchRequestCount.rejected, (state) => {
        // state.isLoading = false;
        state.isError = true;
      });
  },
});

export default requestCountSlice.reducer;
