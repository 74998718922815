// requestCountSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../utils/comman"; // Import your utility functions

export const notification = createAsyncThunk(
  "notification/notification",
  async (_, thunkAPI) => {
    try {
      const response = await utils.callAPI("get", "member/notification/count");
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  notificationCount: null,
  //   isLoading: false,
  isError: false,
};

const notificationCountSlice = createSlice({
  name: "notificationCount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(notification.pending, (state) => {
        // state.isLoading = true;
        state.isError = false;
      })
      .addCase(notification.fulfilled, (state, action) => {
        state.notificationCount = action.payload;
        // state.isLoading = false;
        state.isError = false;
      })
      .addCase(notification.rejected, (state) => {
        // state.isLoading = false;
        state.isError = true;
      });
  },
});

export default notificationCountSlice.reducer;
